import { createRouter, createWebHistory } from "vue-router"
const routes = [
    {
        path: "/",
        component: () => import("@/views/Login.vue"),
    },
    // 登录
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login.vue"),
    },
    //隐私政策
    {
        path: "/privacy",
        name: "privacy",
        component: () => import("@/views/privacy.vue"),
    },
    //用户协议
    {
        path: "/agreement",
        name: "agreement",
        component: () => import("@/views/agreement.vue"),
    },
    {
        path: "/home",
        name: "home",
        component: () => import("@/layout/Home.vue"),
        children: [
            {
                label: "用户管理",
                path: "userManagement",
                name: "userManagement",
                component: () => import("@/views/userManagement/UserManagement.vue")

            },
            {
                label: "楼栋楼层",
                path: "buildingfloors",
                name: "buildingfloors",
                component: () => import("@/views/buildingfloors/Buildingfloors.vue")

            },
            {
                label: "设备管理",
                path: "fixture",
                name: "fixture",
                component: () => import("@/views/device/DeviceOperate.vue")

            },
            // 报警信息管理
            {
                label: "报警信息管理",
                path: 'alarmmanage',
                name: 'alarmmanage',
                component: () => import("@/views/alarmmanage/Alarmmanage.vue"),
            },
            // 报警配置管理
            {
                label: "报警配置管理",
                path: 'alarm',
                name: 'alarm',
                component: () => import("@/views/alarm/Alarm.vue"),
            },
            // 重点部位配置
            {
                label: "重点部位配置",
                path: 'majorparts',
                name: 'majorparts',
                component: () => import("@/views/majorparts/MajorPartsOperate.vue"),
            },
            {
                label: "巡检类型配置",
                path: "patrolScheme",
                name: "patrolScheme",
                component: () => import("@/views/patrolScheme/PatrolScheme.vue")

            },
            {
                label: "巡检计划下发",
                path: "patrol",
                name: "patrol",
                component: () => import("@/views/patrol/PatrolOperate.vue")
            },
            {
                label: "公告",
                path: "noticeDetails",
                name: "noticeDetails",
                component: () => import("@/views/noticeDetails/NoticeDetails.vue")

            }

        ]

    },
    // 管理员首页
    {
        label: "管理员首页",
        path: "/adminhome",
        name: "adminhome",
        component: () => import("@/layout/Adminhome.vue"),
        children: [
            {
                label: "首页",
                path: "adminhomepage",
                name: "adminhomepage",
                component: () => import("@/views/adminhome/adminhomepage/Adminhomepage.vue")
            },
            {
                // 项目管理
                path: 'item',
                name: 'itemmanage',
                component: () => import("@/views/adminhome/item/ItemOperate.vue"),
            },
            {
                label: "员工打卡记录",
                path: "clockIn",
                name: "clockIn",
                component: () => import("@/views/adminhome/clockIn/ClockIn.vue")

            },
            {
                label: "报表下载",
                path: "reportForms",
                name: "reportForms",
                component: () => import("@/views/adminhome/reportForms/ReportForms.vue")
            },
            {
                label: "未维保清单",
                path: "detailedList",
                name: "detailedList",
                component: () => import("@/views/adminhome/reportForms/DetailedList.vue")
            },
            {
                label: "公告管理",
                path: "notice",
                name: "notice",
                component: () => import("@/views/adminhome/notice/Notice.vue")
            },
            {
                label: "灭火器",
                path: "extinguisher/:itemid/:startTime/:endTime",
                name: "extinguisher",
                component: () => import("@/views/adminhome/extinguisher/Extinguisher.vue")

            },
            {
                label: "用户管理",
                path: "userManagement1",
                name: "userManagement1",
                component: () => import("@/views/userManagement/UserManagement.vue")
            },
            {
                label: "楼栋楼层",
                path: "buildingfloors1",
                name: "buildingfloors1",
                component: () => import("@/views/buildingfloors/Buildingfloors.vue")

            },
            {
                label: "设备管理",
                path: "fixture1",
                name: "fixture1",
                component: () => import("@/views/device/DeviceOperate.vue")

            },
            {
                label: "报警信息管理",
                path: 'alarmmanage1',
                name: 'alarmmanage1',
                component: () => import("@/views/alarmmanage/Alarmmanage.vue"),
            },
            // 报警配置管理
            {
                label: "报警配置管理",
                path: 'alarm1',
                name: 'alarm1',
                component: () => import("@/views/alarm/Alarm.vue"),
            },
            {
                label: "设备历史数据",
                path: 'electricityWater',
                name: 'electricityWater',
                component: () => import("@/views/electricityWater/ElectricityWaterData.vue"),
            },
            {
                label: "重点部位配置",
                path: 'majorparts1',
                name: 'majorparts1',
                component: () => import("@/views/majorparts/MajorPartsOperate.vue"),
            },
            {
                label: "巡检类型配置",
                path: "patrolScheme1",
                name: "patrolScheme1",
                component: () => import("@/views/patrolScheme/PatrolScheme.vue")
            },
            {
                label: "巡检计划下发",
                path: "patrol1",
                name: "patrol1",
                component: () => import("@/views/patrol/PatrolOperate.vue")
            },
            {
                label: "设备维保平面图",
                path: "maintained",
                name: "maintained",
                component: () => import("@/views/maintained/Maintained.vue")
            },
            {
                label: "维保统计",
                path: "hostDistinguish",
                name: "hostDistinguish",
                component: () => import("@/views/hostDistinguish/HostDistinguish.vue")
            },
            {
                label: "设备统计表",
                path: "module",
                name: "module",
                component: () => import("@/views/module/index.vue")
            },
            {
                label: "项目概括",
                path: "generalize",
                name: "generalize",
                component: () => import("@/views/generalize/index.vue")
            },
            {
                label: "需求详情",
                path: "demand",
                name: "demand",
                component: () => import("@/views/demand/index.vue")
            },
            {
                label: "维保合同",
                path: "contract",
                name: "contract",
                component: () => import("@/views/contract/index.vue")
            },
            {
                label: "维保计划",
                path: "plan",
                name: "plan",
                component: () => import("@/views/plan/index.vue")
            },
            {
                label: "维保报告书",
                path: "report",
                name: "report",
                component: () => import("@/views/report/Reportmanagement.vue")
            },
            {
                label: "故障订单",
                path: "faultorder",
                name: "faultorder",
                component: () => import("@/views/faultorder/Faultorder.vue")
            },
            {
                label: "智能消火栓",
                path: "firehydrant",
                name: "firehydrant",
                component: () => import("@/views/firehydrant/Firehydrant.vue")
            },
            {
                label: "违规信息",
                path: "illegalInformation",
                name: "illegalInformation",
                component: () => import("@/views/illegalInformation/IllegalInformation.vue")
            },
            {
                label: "视频指南",
                path: "videoguide",
                name: "videoguide",
                component: () => import("@/views/videoguide/Videoguide.vue")
            }

        ]
    },
    {
        label: "数据大屏",
        path: "/echartsPage",
        name: "echartsPage",
        component: () => import("@/views/EchartsPage.vue"),
    },
    {
        label: "CRT",
        path: "/crt",
        name: "crt",
        component: () => import("@/views/Crt.vue"),
    },
    {
        label: "IoT",
        path: "/iot",
        name: "iot",
        component: () => import("@/views/IoT/IoT.vue"),
    },
    // 公众号授权
    {
        path: "/empower",
        name: "empower",
        component: () => import("@/views/move/Empower.vue"),
    },
    {
        path: "/empowerlogin",
        name: "empowerlogin",
        component: () => import("@/views/move/EmpowerLogin.vue"),
    },
    {
        path: "/empowerregister",
        name: "empowerregister",
        component: () => import("@/views/move/EmpowerRegister.vue"),
    },
    // wx 报警
    {
        path: "/alarmform/:openId/:id/:imei/:type",
        // path: "/alarmform",
        name: "alarmform",
        component: () => import("@/views/move/AlarmForm.vue"),
    },
    // 上下班
    {
        path: "/commuting",
        name: "commuting",
        component: () => import("@/views/move/Commuting.vue"),
    },
    {
        path: '/:catchAll(.*)',
        name: '404',
        component: () => import("@/views/404.vue")
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
